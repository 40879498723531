import React, { Component } from "react";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import Img from "react-image";
import { StyleSheet, css } from "aphrodite";
import { withNamespaces } from "react-i18next";
import { StaticQuery, graphql } from "gatsby";
import { Link as ScrollLink } from "react-scroll";

import cn from "classnames";

import Link from "../Link";
import Config from "../../Config";
import Classes from "../../styles/classes";
import pagesInfos from "../../config/i18n/pagesInfos";
import Contact from "./Contact";
import Map from "./Map";
import { t } from "i18next/dist/commonjs";
import Languages, { getLanguage } from "./Languages";

const offset = 0;

const styles = StyleSheet.create({
  footer: {
    backgroundColor: "#002337"
  },
  copy: {
    backgroundColor: "#002337"
  },
  link: {
    cursor: "pointer",
    color: "inherit"
  }
});

const LinkComponent = link => (
  <Link
    page={pagesInfos.ComponentIndex}
    className={cn("click-close", css(styles.link))}
    query={`#${link.name}`}
  >
    {link.label}
  </Link>
);

const ScrollLinkComponent = link => (
  <ScrollLink
    to={link.name}
    className={cn("click-close", css(styles.link))}
    spy
    activeClass={css(styles.active)}
    smooth
    offset={offset}
  >
    {link.label}
  </ScrollLink>
);

const Footer = ({ map, contact, isDisplayFromHome,t }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        services: allContentfulServices(
          filter: { node_locale: { eq: "fr" } }
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              id
              titre
              permalink
              position
              description {
                description
              }
            }
          }
        }
        produits: allContentfulProduits(
          filter: { node_locale: { eq: "fr" } }
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              id
              titre
              permalink
              position
              description {
                description
              }
              service {
                id
              }
            }
          }
        }
      }
    `}
    render={data => {
      const AccueilLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: "accueil",
          label: t('menu.accueil')
        })
      : LinkComponent({
          name: "accueil",
          label: t('menu.accueil')
        });

    const PaddelLink = (
      <Link
        page={pagesInfos.ComponentPaddel}
        className={cn("click-close", css(styles.link))}
        
      >
        {t('menu.paddel')}
      </Link>
    );
    
    const TennisLink = (
      <Link
        page={pagesInfos.ComponentTennis}
        className={cn("click-close", css(styles.link))}
      >
        {t('menu.tennis')}
      </Link>
    );

    const CoursPaddelLink = (
      <Link
        page={pagesInfos.ComponentCoursPaddel}
        className={cn("click-close", css(styles.link))}
        
      >
        {t('menu.coursP')}
      </Link>
    );
    
    const CoursTennisLink = (
      <Link
        page={pagesInfos.ComponentCoursTennis}
        className={cn("click-close", css(styles.link))}
      >
        {t('menu.coursT')}
      </Link>
    );

    const TouchTennisLink = (
      <Link
        page={pagesInfos.ComponentTouchTennis}
        className={cn("click-close", css(styles.link))}
      >
        {t('menu.touchtennis')}
      </Link>
    );

    const RestaurantLink = (
      <Link
        page={pagesInfos.ComponentRestaurant}
        className={cn("click-close", css(styles.link)) }
        
      >
        {t('menu.restaurant')}
      </Link>
    );

    const PhotosLink = (
      <Link
        page={pagesInfos.ComponentPhotos}
        className={cn("click-close", css(styles.link)) }
        
      >
        {t('menu.photos')}
      </Link>
    );

    const EventsLink = (
      <Link
        page={pagesInfos.ComponentEvents}
        className={cn("click-close", css(styles.link)) }
        
      >
        {t('menu.events')}
      </Link>
    );

    const JeuxDeCartesLink = (
      <Link
        page={pagesInfos.ComponentJeuxDeCartes}
        className={cn("click-close", css(styles.link))}
      >
        {t('menu.jeuxdecartes')}
      </Link>
    );
    
    const ContactLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: "contact",
          label: t('menu.contact')
        })
      : LinkComponent({
          name: "contact",
          label: t('menu.contact')
        });
      return (
        <footer
          className="main-footer"
          style={{
            textAlign: "center"
          }}
        >
          <svg
            preserveAspectRatio="none"
            viewBox="0 0 100 102"
            height="100"
            width="100%"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className="svgcolor-light"
            style={{
              zIndex: 0
            }}
          >
            <path d="M0 0 L50 100 L100 0 Z" fill="#fff" stroke="#fff" />
          </svg>
          <div className="container">
            <div className="row mt-100 mb-50 footer-widgets">
              <div className="col-md-4 col-xs-12">
                <div className="footer-widget contact-widget">
                  <div className={css(Classes.center)}>
                    <Img
                      src="/assets/img/MONTJOIE_Logo_DEF_2022_blanc.png"
                      className="logo-footer img-responsive"
                      alt="Footer Logo"
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                  <p>
                    Rue Edith Cavell 91 <br />
                    BE-1180 Uccle <br />
                    Belgique <br />
                  </p>
                  
                </div>
              </div>

              <div className="col-md-4 col-xs-12" 
              style = {{paddingTop: "70px"}}
              >
                <div className="footer-widget twitter-widget">
                  <h4>Contact</h4>

                  <p><b>{t('footer.title1')}</b></p>
                  <p style={{ fontSize: '12px'}}>
                  {t('footer.address1')}
                    <br />
                    <a href={t('footer.site1')}>{t('footer.site1')}</a>
                    <br />
                    {t('footer.phone1')}
                    <br />
                    {t('footer.email1')}
                    <br />
                  </p>
                  
                  <br />
                </div>
              </div>

              <div className="col-md-4 col-xs-12"
              style = {{paddingTop: "70px"}}
              >
                <div className="footer-widget company-links">
                  <h4>Menu</h4>
                  <ul className="footer-links">
                    <li>{AccueilLink}</li>
                    <li>{PaddelLink}</li>
                    <li>{TennisLink}</li>
                    <li>{TouchTennisLink}</li>

                    <li>{CoursPaddelLink}</li>
                    <li>{CoursTennisLink}</li>
                    <li>{PhotosLink}</li>
                    <li>{EventsLink}</li>
                    
                    <li>{RestaurantLink}</li>
                    
                    <li>{JeuxDeCartesLink}</li>
                    <li>{ContactLink}</li>
                    <li><Languages /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      );
    }}
  />
);

Footer.propTypes = {
  map: PropTypes.bool,
  contact: PropTypes.bool
};

Footer.defaultProps = {
  map: false,
  contact: false
};

export default withNamespaces("home")(Footer);

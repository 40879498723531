import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import cn from "classnames";
import swal from "sweetalert2";
import { ContactTemplate, ContactTemplateSection } from "inno-components";
import Colors from "../../styles/colors";
import Config from "../../Config";

import FormBuilder from "inno-components/build/FormBuilder";
import FormField from "inno-components/build/FormField";
import Gdpr from "./Gdpr";

import Classes from "../../styles/classes";

export const sendMail = (from, to, subject, domain, html, attachements) => {
  const data = new FormData();
  data.append("from", from);
  data.append("to", to);
  data.append("subject", subject);
  data.append("domain", domain);
  data.append("html", html);

  if (attachements && attachements.length > 0) {
    attachements.forEach((attachement, i) => {
      data.append(`file${i}`, attachement);
    });
  }

  return axios.post(
    "https://us-central1-innobelge-websites.cloudfunctions.net/emails",
    data
  );
};

const styles = StyleSheet.create({
  section: {},
  footer: {
    backgroundColor: "#002337"
  },
  copy: {
    backgroundColor: "#002337"
  },
  input: {
    display: "block",
    width: "100%",
    fontSize: "14px",
    border: 0,
    padding: "10px 20px",
    backgroundColor: "#fff",
    borderBottom: "1px dashed #e0e0e1",
    color: "#0f3a70"
  },
  inputError: {
    color: "#e74c3c",
    borderBottom: "1px dashed #e74c3c",
    "::-webkit-input-placeholder": {
      color: "#e74c3c"
    }
  },
  error: {
    color: "#e74c3c",

    fontSize: "13px",
    marginTop: "8px"
  },
  dropzone: {
    width: "100%",
    margin: "1.5em 0",
    minHeight: "150px",
    borderRadius: "25px",
    display: "block",
    backgroundColor: "#F6F9FF",
    fontWeight: "bold",
    cursor: "pointer"
  },
  dropzoneContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5em"
  },
  iconDropzone: {
    color: "inherit",
    background: "#fff",
    textAlign: "center",
    margin: "0.5em"
  },
  textarea: {
    width: "100%",
    display: "block",
    marginTop: "1.5em",
    color: "#0f3a70"
  },
  checkbox: {
    color: "#0f3a70"
  },
  submit: {
    margin: "2em",
    fontSize: "15px",
    color: "#0f3a70"
  }
});

class Footer extends Component {
  
  state = {
    isLoading: false
  };
  
  componentDidMount() {}
  onSubmit = response => {
    const { t } = this.props;
    this.setState(
      {
        isLoading: true
      },
      () => {
        const html = [];
        response.values.forEach(value => {
          if (typeof value.label == "string") {
            html.push(`<h3>${value.label}</h3><p>${value.value}</p>`);
          } else {
            html.push(
              `<h3>${
                typeof value.label == "string"
                  ? value.label
                  : t('le_club.contact.gdpr_title')
              }</h3><p><small> ${t('le_club.contact.gdpr_text')} </small></p><strong>${
                value.value ? "Accepté" : "Refusé"
              }</strong>`
            );
          }
          
        });

        const section = ContactTemplateSection(
          "Nouvelle Demande De Contact",
          html.join("")
        );
        const template = ContactTemplate(
          "https://www.padeltennismontjoie.be/assets/img/MONTJOIE_Logo_DEF_2022_2.png",//"https://t.com/assets/img/MONTJOIE_Logo_DEF_2022_2.png",
          section
        );

        console.log(html.join(""))
        console.log(section)
        console.log(template)

        sendMail(
          "info@padeltennismontjoie.be", //from
          "francesco_i_87@hotmail.com;info@padeltennismontjoie.be", //to
          "Nouvelle Demande De Contact",
          "innobelge.be",
          template,
          response.files.fichiers
        )
          .then(() => {
            swal(
              t('le_club.contact.success_send_1'),
              t('le_club.contact.success_send_2'),
              t('le_club.contact.success_send_3')
            );
          })
          .catch(error => {
            console.log("error send message : ",error);
            swal(t('le_club.contact.error_send'), error, "error");
          });
      }
    );
  };
  render() {
    const { t } = this.props;
      
    const fields = [
      [
        FormField({
          label: t('le_club.contact.field_name'),
          name: "nom",
          type: "text",
          colClassName: "col-lg-12 col-md-12 col-sm-12 col-xs-12",
          required: true,
          errorMessage: t('le_club.contact.field_name_error')
        })
      ],
      [
        FormField({
          label: t('le_club.contact.field_email'),
          name: "email",
          type: "text",
          colClassName: "col-lg-12 col-md-12 col-sm-12 col-xs-12",
          required: true,
          errorMessage: t('le_club.contact.field_email_error'),
          validate: value => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return {
              isValid: re.test(String(value).toLowerCase()),
              message: t('le_club.contact.field_email_invalide')
            };
          }
        })
      ],
      [
        FormField({
          label: t('le_club.contact.field_telephone'),
          name: "telephone",
          type: "text",
          colClassName: "col-lg-12 col-md-12 col-sm-12 col-xs-12",
          required: true,
          errorMessage: t('le_club.contact.field_telephone_error'),
          validate: value => {
            const re = /^((\+|00)\d{2}\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/;
            return {
              isValid: re.test(String(value).toLowerCase()),
              message: t('le_club.contact.field_telephone_invalide')
            };
          }
        })
      ],
      [
        FormField({
          label: t('le_club.contact.field_sujet'),
          name: "sujet-demande",
          type: "text",
          colClassName: "col-lg-12 col-md-12 col-sm-12 col-xs-12"
        })
      ],
      [
        FormField({
          label: t('le_club.contact.field_message'),
          name: "messages",
          type: "textarea",
          colClassName: "col-lg-12 col-md-12 col-sm-12 col-xs-12"
        })
      ]
    ];

    return (
      <FormBuilder
        fields={fields}
        showLabels={false}
        inputClassName={css(styles.input)}
        inputErrorClassName={css(styles.inputError)}
        gridClassName=""
        fullWidthClassName="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        errorClassName={css(styles.error)}
        textAreaClassName={css(styles.textarea)}
        dropzoneClassName={css(styles.dropzone)}
        labelClassName={css(styles.label)}
        checkboxClassName={css(styles.checkbox)}
        submitLabel="Envoyer"
        dropzoneContent={
          <div className={css(styles.dropzoneContent)}>
            <div className="item-icon">
              <span
                className={cn("icon", "icon-upload", css(styles.iconDropzone))}
              />
            </div>
            <p>
              Vous pouvez cliquer ou glisser des fichiers ici afin de les
              ajouter au devis
            </p>
          </div>
        }
        onSubmit={this.onSubmit}
        buttonSubmitPosition="center"
        buttonSubmitClassName={css(styles.submit)}
      />
    );
  }
}

export default withNamespaces("home")(Footer);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import chroma from "chroma-js";
import { compose } from "recompose";
import { Breakpoints, MediaQueries } from "inno-components";
import { Link as ScrollLink } from "react-scroll";
import cn from "classnames";
import $ from "jquery";
import Img from "react-image";
import Config from "../../Config";

import Link from "../Link";
import pagesInfos from "../../config/i18n/pagesInfos";
import ScrollPosition from "../utils/ScrollPosition";
import Languages, { getLanguage } from "./Languages";

const offset = 0;

const styles = StyleSheet.create({
  active: {
    color: Config.colors.secondary
  },
  link: {
    cursor: "pointer",
    color: "#fff",
    ":hover": {
      color: Config.colors.secondary
    },
    [MediaQueries.small]: {
      fontSize: "1.1em",
      fontWeight: "bold",
      color: Config.colors.primary
    }
  },
  navbarCollapse: {
    display: "flex",
    width: "auto",
    justifyContent: "flex-end"
  },
  header: {
    [MediaQueries.medium]: {
      display: "flex",
      justifyContent: "space-between",
      alignItem: "center"
    }
  },
  menuIcon: {
    display: "static",
    [MediaQueries.medium]: {
      display: "none"
    }
  },
  menuDesktop: {
    display: "none",
    [MediaQueries.medium]: {
      display: "flex",
      alignItems: "center",
      marginBottom: 0,
      marginRight: "50px"
    }
  },
  itemMenuDesktop: {
    display: "inline",
    margin: "0 1em",
    color: Config.colors.primary
    
  }
});

const LinkComponent = link => (
    <Link
      page={pagesInfos.ComponentIndex}
      className={cn("click-close", css(styles.link))}
      query={`#${link.name}`}
    >
      {link.label}
    </Link>
);

const ScrollLinkComponent = link => (
  <ScrollLink
    to={link.name}
    className={cn("click-close", css(styles.link))}
    spy
    activeClass={css(styles.active)}
    smooth
    offset={offset}
  >
    {link.label}
  </ScrollLink>
);

class Menu extends Component {
  
  

  constructor(props) {
    super(props);
    
    this.state = {
      open: false
    };
  }
  onToggleMenu = () => {
    this.setState({
      open: !this.state.open
    });
    this.setState({
      open: !this.state.open
    });
  };

  componentDidMount() {}

  render() {
    const { isDisplayFromHome } = this.props;
    const { t } = this.props

    const AccueilLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: "accueil",
          label: t('menu.accueil')
        })
      : LinkComponent({
          name: "accueil",
          label: t('menu.accueil')
        });

    const PaddelLink = (
      <Link
        page={pagesInfos.ComponentPaddel}
        className={cn("click-close", css(styles.link))}
        
      >
        {t('menu.paddel')}
      </Link>
    );
    
    const TennisLink = (
      <Link
        page={pagesInfos.ComponentTennis}
        className={cn("click-close", css(styles.link))}
      >
        {t('menu.tennis')}
      </Link>
    );

    const CoursPaddelLink = (
      <Link
        page={pagesInfos.ComponentCoursPaddel}
        className={cn("click-close", css(styles.link))}
        
      >
        {t('menu.coursP')}
      </Link>
    );
    
    const CoursTennisLink = (
      <Link
        page={pagesInfos.ComponentCoursTennis}
        className={cn("click-close", css(styles.link))}
      >
        {t('menu.coursT')}
      </Link>
    );

    const TouchTennisLink = (
      <Link
        page={pagesInfos.ComponentTouchTennis}
        className={cn("click-close", css(styles.link))}
      >
        {t('menu.touchtennis')}
      </Link>
    );

    const RestaurantLink = (
      <Link
        page={pagesInfos.ComponentRestaurant}
        className={cn("click-close", css(styles.link)) }
        
      >
        {t('menu.restaurant')}
      </Link>
    );

    const PhotosLink = (
      <Link
        page={pagesInfos.ComponentPhotos}
        className={cn("click-close", css(styles.link)) }
        
      >
        {t('menu.photos')}
      </Link>
    );

    const EventsLink = (
      <Link
        page={pagesInfos.ComponentEvents}
        className={cn("click-close", css(styles.link)) }
        
      >
        {t('menu.events')}
      </Link>
    );

    const JeuxDeCartesLink = (
      <Link
        page={pagesInfos.ComponentJeuxDeCartes}
        className={cn("click-close", css(styles.link))}
      >
        {t('menu.jeuxdecartes')}
      </Link>
    );
    
    const ContactLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: "contact",
          label: t('menu.contact')
        })
      : LinkComponent({
          name: "contact",
          label: t('menu.contact')
        });
    

    return (
      <header className="main-header">
        <div className="container-fluid">
          <div className={cn("box-header", css(styles.header))}>
            <div className="box-logo">
              <Link
                page={pagesInfos.ComponentIndex}
                className={cn("click-close", css(styles.link))}
              >
                <Img src="/assets/img/MONTJOIE_Logo_DEF_2022_2.png" width="180" alt="Logo" />
              </Link>
            </div>
            <div className={css(styles.menuDesktop)}>
              <div className="navigation">
                {AccueilLink}
              </div>

              <div className="navigation">
                <a href="#" className={cn("click-close", css(styles.link))}>{t('menu.reserver')}</a>
                <div className="navigation-content">
                  {PaddelLink}
                  {TennisLink}
                  {TouchTennisLink}
                </div>
              </div>

              <div className="navigation">
                <a href="#" className={cn("click-close", css(styles.link))}>{t('menu.cours')}</a>
                <div className="navigation-content">
                  {CoursPaddelLink}
                  {CoursTennisLink}
                </div>
              </div>

              <div className={cn("navigation")}>
                {PhotosLink}
              </div>

              <div className={cn("navigation")}>
                {EventsLink}
              </div>

              <div className={cn("navigation")}>
                {RestaurantLink}
              </div>
              

              <div className="navigation">
                {JeuxDeCartesLink}
              </div>

              <div className="navigation">
                {ContactLink}
              </div>

              <div className="navigation">
                <Languages />
              </div>
            </div>

            {
              /*
            <ul className={css(styles.menuDesktop)}>
              <li className={css(styles.itemMenuDesktop)}>{AccueilLink}</li>
              <li className={css(styles.itemMenuDesktop)}>
                {t('menu.reserver')}
                <ul>
                  <li className={css(styles.itemMenuDesktop)}>{AccueilLink}</li>
                </ul>
              </li>
              <li className={css(styles.itemMenuDesktop)}>{TennisEteLink}</li>
              <li className={css(styles.itemMenuDesktop)}>
                {TennisCouvertLink}
              </li>
              <li className={css(styles.itemMenuDesktop)}>{NotreEquipeLink}</li>
              <li className={css(styles.itemMenuDesktop)}>{ContactLink}</li>
              <li><Languages /></li>
            </ul>
            **/
            }
            <a
              className={cn("box-primary-nav-trigger", css(styles.menuIcon))}
              href="javascript:void(0);"
            >
              <span className="box-menu-icon" />
            </a>
          </div>

          <nav>
            <ul className="box-primary-nav">
              <li className="box-label">Navigation</li>
              <li>{AccueilLink}</li>
              <li>{PaddelLink}</li>
              <li>{TennisLink}</li>
              <li>{TouchTennisLink}</li>
              <li>{CoursPaddelLink}</li>
              <li>{CoursTennisLink}</li>
              <li>{PhotosLink}</li>
              <li>{EventsLink}</li>
              <li>{RestaurantLink}</li>
              <li>{JeuxDeCartesLink}</li>
              <li>{ContactLink}</li>
              <li><Languages /></li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

const enhance = compose(
  withNamespaces("home"),
  ScrollPosition
);

Menu.propTypes = {
  isDisplayFromHome: PropTypes.bool
};

Menu.defaultProps = {
  isDisplayFromHome: true
};

export default enhance(Menu);

import React from "react";
import { MaterialDialog } from "inno-components";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class ScrollDialog extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = e => {
    e.preventDefault();
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { t } = this.props;
    console.log("dfsdf", this.props);
    return (
      <React.Fragment>
        <div style={{ fontSize: "13px", marginTop: "2em" }}>
          {" "}
          <span>
            {t('le_club.contact.gdpr_text')}
            
          </span>{" "}
          <a
            onClick={this.handleClickOpen}
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              textDecoration: "underline"
            }}
          >
            politique de confidentialité
          </a>{" "}
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h3>Tennis Club Uccle Churchill - Politique de confidentialité</h3>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontSize: "15px" }}>
              <p style={{ marginTop: "1em" }}>
                Ce site peut être amené à collecter des données personnelles
                vous concernant. Ces données sont collectées directement auprès
                des internautes. La présente Politique énonce donc les principes
                et lignes directrices pour la protection de vos données
                personnelles et a pour objectif de vous informer sur :
              </p>
              <ul
                style={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                  padding: "1em"
                }}
              >
                <li>
                  Les données personnelles que ce site collecte et les raisons
                  de cette collecte,
                </li>
                <li>La façon dont sont utilisées ces données personnelles,</li>
                <li>
                  Vos droits sur les données personnelles vous concernant.
                </li>
              </ul>
              <h4 style={{ marginTop: "3em" }}>
                Identité et coordonnées du responsable du traitement
              </h4>
              <p style={{ marginTop: "1em" }}>
                Tennis Club Uccle Churchill (« Le Club »), 92, Rue E. Cavell,
                1180 Uccle
              </p>
              <h5 style={{ marginTop: "3.5em" }}>
                Finalités de la collecte de données
              </h5>
              <p style={{ marginTop: "1em" }}>
                Les données sont collectées afin de pouvoir répondre à un
                internaute lorsque celui-ci demande des renseignements depuis
                l’onglet ‘Contact’.
              </p>
              <h5 style={{ marginTop: "3.5em" }}>
                Type de données à caractère personnel utilisées
              </h5>
              <p style={{ marginTop: "1em" }}>
                Ce site est susceptible de collecter vos données personnelles
                suivantes : nom, prénom, adresse, date de naissance, numéros de
                téléphone (fixe, mobile, bureau), e-mail.
              </p>
              <h5 style={{ marginTop: "3.5em" }}>
                Transfert de vos données personnelles en dehors de l’Union
                Européenne
              </h5>
              <p style={{ marginTop: "1em" }}>
                Le Tennis Club Uccle Churchill réalise l’ensemble des
                traitements de vos données personnelles sur le territoire de
                l’Union Européenne (UE).
              </p>
              <h5 style={{ marginTop: "3.5em" }}>Conservation des données</h5>
              <p style={{ marginTop: "1em" }}>
                Vos données personnelles sont conservées pendant une durée qui
                n’excède pas la durée nécessaire aux finalités pour lesquelles
                elles sont collectées et traitées, puis détruites après 5 ans.
              </p>
              <h5 style={{ marginTop: "3.5em" }}>Vos droits</h5>
              <p style={{ marginTop: "1em" }}>
                Toute personne dont les données personnelles sont traitées par
                le Tennis Club Uccle Churchill dispose des droits suivants
                relatifs aux données à caractère personnel la concernant : un
                droit d’accès, un droit à l’information, un droit de
                rectification, un droit à l’effacement, un droit de limitation
                du traitement, un droit d’opposition
              </p>
              <p style={{ marginTop: "1em" }}>
                Si vous souhaitez exercer ces droits, vous pouvez contacter le
                Club par e-mail à : tennischurchill1180@gmail.com , ou par
                courrier postal à l’adresse suivante : 92, Rue Edith Cavell,
                1180 Uccle. Le Club s’engage à répondre à vos demandes
                d’exercice de vos droits dans un délai de 14 jours.
              </p>
              <h5 style={{ marginTop: "3.5em" }}>Cookies</h5>
              <p style={{ marginTop: "1em" }}>
                Lors de vos visites sur ce site, un cookie peut s’installer dans
                votre logiciel de navigation. Un cookie est un fichier texte
                susceptible d’être enregistré, sous réserve de vos choix, dans
                un espace dédié du disque dur de votre terminal. Il n’a pas pour
                objet d’identifier les utilisateurs du site, mais enregistre des
                informations relatives à la navigation sur le site et à
                mémoriser vos préférences. Il est nécessaire au bon
                fonctionnement du site. Il permet d’améliorer la qualité de
                service et peut mesurer l’audience. En utilisant le site, vous
                acceptez l’utilisation des cookies.
              </p>
              <p>
                Plusieurs possibilités vous sont offertes pour 2 gérer les
                cookies. Lors de vos visites du site, des cookies peuvent
                s’installer automatiquement dans votre logiciel de navigation.
                Vous avez la possibilité de les refuser en recourant aux options
                de votre navigateur (dont les instructions diffèrent selon le
                constructeur de votre terminal, ils permettent la gestion et/ou
                la suppression des cookies implantés, à la discrétion de
                l’internaute).
              </p>
              <p>
                Nous vous rappelons que le paramétrage est susceptible de
                modifier vos conditions d’accès à nos contenus et services
                nécessitant l’utilisation de cookies. Afin de gérer les cookies
                au plus près de vos attentes, nous vous invitons à paramétrer
                votre navigateur en tenant compte de la finalité des cookies. A
                défaut, la suppression des cookies est susceptible de limiter
                certaines fonctionnalités ou possibilités de navigation sur le
                site.
              </p>
              <h5 style={{ marginTop: "3.5em" }}>Désactivation des cookies</h5>
              <p style={{ marginTop: "1em" }}>
                L’utilisateur du site est toutefois informé que la plupart des
                navigateurs Internet permettent de s’opposer à l’enregistrement
                des cookies, de recevoir un message d’avertissement avant qu’un
                cookie ne soit copié, ou de supprimer les cookies de son disque
                dur. L’utilisateur devra, pour en savoir plus sur ces fonctions,
                se reporter au menu d’aide du navigateur utilisé.
              </p>
              <p style={{ marginTop: "1em" }}>
                dant, si l’utilisateur s’oppose à l’installation des cookies ou
                procède à leur suppression, l’utilisation du site pourrait se
                révéler altérée, voire impossible.
              </p>
              <h5 style={{ marginTop: "3.5em" }}>
                Définitions au sens du RGPD
              </h5>
              <ul
                style={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                  padding: "1em"
                }}
              >
                <li>
                  Données personnelles : toute information se rapportant à vous
                  et permettant de vous identifier directement ou indirectement.
                </li>
                <li>
                  Traitement : toute opération ou tout ensemble d’opérations
                  appliquées à vos données personnelles.
                </li>
                <li>
                  Responsable du traitement : le Tennis Club Uccle Churchill qui
                  réalise le traitement de vos données personnelles.
                </li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ScrollDialog;

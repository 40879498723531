module.exports = {
  ComponentIndex: {
    id: 'ComponentIndex',
    namespace: 'home',
    path: {
      fr: '/',
      en: '/en/'
    }
  },
  

  ComponentPaddel: {
    id: 'ComponentPaddel',
    namespace: 'home',
    path: {
      fr: '/paddel',
      en: '/en/paddel'
    }
  },
  ComponentTennis: {
    id: 'ComponentTennis',
    namespace: 'home',
    path: {
      fr: '/tennis',
      en: '/en/tennis'
    }
  },

  ComponentCoursPaddel: {
    id: 'ComponentCoursPaddel',
    namespace: 'home',
    path: {
      fr: '/cours-paddel',
      en: '/en/cours-paddel'
    }
  },
  ComponentCoursTennis: {
    id: 'ComponentCoursTennis',
    namespace: 'home',
    path: {
      fr: '/cours-tennis',
      en: '/en/cours-tennis'
    }
  },
  
  ComponentTouchTennis: {
    id: 'ComponentTouchTennis',
    namespace: 'home',
    path: {
      fr: '/touch-tennis',
      en: '/en/touch-tennis'
    }
  },
  ComponentRestaurant: {
    id: 'ComponentRestaurant',
    namespace: 'home',
    path: {
      fr: '/restaurant',
      en: '/en/restaurant'
    }
  },
  ComponentPhotos: {
    id: 'ComponentPhotos',
    namespace: 'home',
    path: {
      fr: '/photos',
      en: '/en/pictures'
    }
  },
  ComponentEvents: {
    id: 'ComponentEvents',
    namespace: 'home',
    path: {
      fr: '/events',
      en: '/en/events'
    }
  },
  ComponentJeuxDeCartes: {
    id: 'ComponentJeuxDeCartes',
    namespace: 'home',
    path: {
      fr: '/jeux-de-cartes',
      en: '/en/jeux-de-cartes'
    }
  },



  ComponentTennisEte: {
    id: 'ComponentTennisEte',
    namespace: 'home',
    path: {
      fr: '/tennis-ete',
      en: '/en/blog'
    }
  },
  ComponentTennisCouvert: {
    id: 'ComponentTennisCouvert',
    namespace: 'home',
    path: {
      fr: '/tennis-couvert',
      en: '/en/team'
    }
  },
  ComponentEcoleDeTennis: {
    id: 'ComponentEcoleDeTennis',
    namespace: 'home',
    path: {
      fr: '/ecole-de-tennis',
      en: '/en/team'
    }
  }
}

const config = {
  url: 'https://www.padeltennismontjoie.be',
  name: 'padeltennismontjoie',
  head: {
    image: 'https://www.padeltennismontjoie.be/assets/img/MONTJOIE_Logo_DEF_2022_2.jpg'
  },
  facebook: {
    prefix:
      'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# business: http://ogp.me/ns/business#"',
    type: 'business.business'
  },
  colors: {
    primary: '#082d5B',//'#23408F',
    secondary: '#A5C61A',//'#F4C723',
    thirdary: '#E3ED35',//'#002337'
  },
  langs: {
    default: 'fr',
    locales: ['fr', 'en', 'nl']
  },
  fonts: {
    defaultSize: '18px',
    primary: {
      name: "'Open Sans', sans-serif",
      url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800'
    },
    secondary: {
      name: "'Playfair Display', sans-serif",
      url:
        'https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900'
    }
  }
}

export default config
